<template>
  <CHeader :style="'background-color: '+c1+' !important;'">
      <CRow class="mobile" style="width: 100%; margin: 0px;">
        <img class="logo" src="main_logo.jpg" style="" @click="anchor('inicio')">    
        <CCol lg="12" sm="12" :style="'background-color: '+c2+' !important; height: 24px;'"></CCol>
        <CCol sm="12" class="text-center">
          <CRow class="header-url-no-border" style="width: 100%;padding: 0px !important; margin: auto !important;">
            <table style="width: 100%">
              <tr>
                <td style="width: 40%"><img src="main_logo.jpg" style="height: 85px; padding: 10px 5px;" @click="anchor('inicio')"></td>
                <td style="width: 30%; text-align: right !important; padding-right: 20px;">
                  <a href="javascript:;" @click="anchor('payments')">Pagos</a>
                </td>
                <td style="width: 30%; text-align: left !important; padding-right: 20px;">
                  <a href="javascript:;" @click="anchor('tickets')">Boletos</a>
                </td>
              </tr>
            </table>
          </CRow>  
        </CCol>
        <CCol lg="12" sm="12" :style="'background-color: '+c2+' !important; height: 24px;padding: 0px; margin: 0px;'"></CCol>
      </CRow>
      <CRow style="width: 100%; margin: 0px;" class="desktop">
        <img class="logo" src="main_logo.jpg" style="" @click="anchor('inicio')">
        <CCol lg="12" sm="12" :style="'background-color: '+c2+' !important; height: 25px;'"></CCol>
        <CCol lg="1" sm="1" class="text-center">
          <img src="main_logo.jpg" style="height: 85px; margin: 0 50px 0 0;" @click="anchor('inicio')">    
        </CCol>
        <CCol lg="10" sm="10" class="h-auto" style='font-family: "Lato", sans-serif;'>         
          <CRow class="header-url-no-border" style="padding: 10px; font-size: 18px; text-align: right;">
            <CCol lg="12" sm="12">
              <a href="javascript:;" @click="anchor('nosotros')" style="margin: 0 10px;" class="nosotros">Nosotros</a>
              <a href="javascript:;" @click="anchor('garantia')" style="margin: 0 10px;" class="garantia">Garantía</a>
              <a href="javascript:;" @click="anchor('contacto')" style="margin: 0 10px;" class="contacto">Contacto</a>
              <a href="javascript:;" @click="anchor('payments')" style="margin: 0 10px;">Pagos</a>
              <a href="javascript:;" @click="anchor('tickets')" style="margin: 0 10px;">Boletos</a>
            </CCol>
          </CRow>                                                                                        
        </CCol>  
        <CCol lg="12" sm="12" :style="'background-color: '+c2+' !important; height: 25px;'">
        </CCol>
      </Crow>
  </CHeader>
</template>

<script>
import router from '../router/index'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import account_ws from '../services/account';
import login from '../services/login';

export default {
  name: 'TheHeaderLanding',
  components: {
    TheHeaderDropdownAccnt
  },
  props: {
    c1: {
      type: String,
      default: '#000000'
    },
    c2: {
      type: String,
      default: '#F29200'
    }
  },
  data () {
    return { 
    }
  },
  mounted: async function() {
    
  },
  methods: {
    anchor(anchor_id){
      this.$emit("anchor", anchor_id );
    }
  }
}
</script>
<style>
@media(min-width: 600px){
    .mobile{
      display: none;
    }
}
@media(max-width: 600px){
    .desktop{
      display: none;
    }
}
@media(max-width: 720px){
  .nosotros{
    display: none;
  }

  .garantia{
    display: none;
  }

  .contacto{
    display: none;
  }
}

.header-url{
  height: 80px;
  width: auto;
  padding: 10px;
  margin-top: 25px;
  color: #fff;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;  
  border: 2px solid transparent;
  border-right-color: #fff;
  cursor: pointer;
}
.header-url a {
  color: #fff;
  text-decoration: none;
}
.header-url-no-border{
  height: auto;
  width: auto;
  padding: 10px;
  margin-top: 25px;
  color: #fff;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;  
    
}
.header-url-no-border a{
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
a:hover{
  color:gray;
}
.logo{
  position: absolute;
  left: 0%;
  z-index:100;
  height: 135px;
  margin: 0 50px 0 0;
}
</style>

<template>
    <div>
      <!-- NOSOTROS -->
      <CRow id="nosotros" ref="nosotros" class="home-section-one">
        <CCol class="home-title" sm="12" :style='"background-color: "+raffle.color_2'>
          <h3>ACERCA DE NOSOTROS</h3>
        </CCol>
        <CCol sm="12" style="height: 20px;"></CCol>
          <CCol sm="3"></CCol>
          <CCol sm="6" class="text-center">
          <h4>Realizamos rifas totalmente con transparencia y legalidad 100% confiabilidad, nuestros seguidores y los premios entregados nos respaldan.</h4><br /><br />
          <img src="main_logo.jpg" style="height: 100px; margin: 0 50px;" alt="Logo Oscuro">    
          </CCol>
          <CCol sm="3"></CCol>
        <CCol sm="12" style="height: 20px;"></CCol>
      </CRow>
      <!-- GARANTIA -->
      <CRow id="garantia" ref="garantia" class="home-section-one">
        <CCol class="home-title" sm="12" :style='"background-color: "+raffle.color_2'>
          <h3>GARANTÍA DE TRANSPARENCIA</h3>
        </CCol>
        <CCol sm="12" style="height: 20px;"></CCol>
          <CCol sm="3"></CCol>
          <CCol sm="6">
            <p align="justify">
              <ol>
                <li>El número GANADOR siempre es en base a los últimos 5 dígitos de la LOTERÍA NACIONAL, en caso de haber cambio de fecha de sorteo en el calendario de Lotería Nal. recorremos la rifa a la fecha de su siguiente sorteo (nunca utilizamos otra dinámica como tómbolas, bingo, etc.).</li>
                <li>Publicamos la LISTA de todos los PARTICIPANTES de la RIFA en nuestra página de Facebook, antes de llevar a cabo el evento.</li>
                <li>Transmitimos EN VIVO por Facebook la RIFA, al mismo tiempo que LOTERÍA NACIONAL, así como la entrega del premio al GANADOR.</li>
                <li>IMPORTANTE: Fecha de Rifa del premio mayor sujeto al 70% o más de boletos vendidos.</li>
                <li>En caso de que no se cumpla con el mínimo de venta se pospondría durante alguna semana o semanas más respetando los boletos emitidos, apartados y vendidos.</li>
              </ol>
            </p>
          </CCol>
          <CCol sm="3"></CCol>
        <CCol sm="12" style="height: 20px;"></CCol>
      </CRow>
      <!-- CONTACTO -->
      <CRow id="contacto" ref="contacto" class="home-section-one">
        <CCol class="home-title" sm="12" :style='"background-color: "+raffle.color_2'>
          <h3>CONTACTANOS</h3>
        </CCol>
        <CCol sm="12" style="height: 20px;"></CCol>
      </CRow>
      <CRow>
        <CCol class="home-subtitle" sm="12" :style='"color: "+raffle.color_2'>
          <h3><a href="https://wa.me/+523751461635" target="_blank">PREGUNTAS AL WHATSAPP +523751461635</a></h3>
        </CCol>
        <CCol sm="12" style="height: 20px;"></CCol>
          <CCol sm="3"></CCol>
          <CCol sm="6" class="text-center">
            <Facebook />
          </CCol>
          <CCol sm="3"></CCol>
        <CCol sm="12" style="height: 20px;"></CCol>
      </CRow>
    </div>
</template>

<script>

import Facebook from './Facebook'

export default {
  name: 'Sections',
  components: {
    Facebook
  },
  props: {
    raffle: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      title: "Secciones"
    }
  },
  mounted: async function() {
      
  },
  methods: {
    
  }
}
</script>
<style scoped>
.home-section-one{
  margin: 0px;
}
.home-title{
    display: flex;
    width: 100%;
    color: #fff;
    height: 80px;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 54px;
    text-shadow: 1px 3px 5px #000;
    align-items: center;
    justify-content: center;
}
h1, h3{
  font-weight: bolder;
}
.home-subtitle{
  text-align: center;
  margin-top: 10px;
  text-shadow: 1px 3px 5px #AAA;
  font-family: "Lato", sans-serif;
}
.home-subtitle a{
  text-align: center !important;
  margin-top: 10px !important;
  text-shadow: 1px 3px 5px #AAA !important;
  font-family: "Lato", sans-serif !important;
}
.home-icon a{
  display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    line-height: normal;
    height: auto;
    min-height: 20px;
    min-width: 20px;
    overflow: hidden;
    margin: 0 5px 0 -1px;
    padding: 0 24px;
    cursor: pointer;
    pointer-events: auto;
}
.c-body{
  font-family: "Lato", sans-serif !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.carousel-caption{
  color:#000  !important;
}
.row{
  margin:0px;
}
</style>

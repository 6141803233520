<template>
    <div>
      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61559945600378&tabs=timeline&width=310&height=134&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1891761137961634" width="310" height="134" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div>
</template>

<script>


export default {
  name: 'Sections',
  props: {
    raffle: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      title: "Secciones"
    }
  },
  mounted: async function() {
      
  },
  methods: {
    
  }
}
</script>
<style scoped>
.home-section-one{
  margin: 0px;
}
.home-title{
    display: flex;
    width: 100%;
    color: #fff;
    height: 80px;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 54px;
    text-shadow: 1px 3px 5px #000;
    align-items: center;
    justify-content: center;
}
h1, h3{
  font-weight: bolder;
}
.home-subtitle{
  text-align: center;
  margin-top: 10px;
  text-shadow: 1px 3px 5px #AAA;
  font-family: "Lato", sans-serif;
}
.home-subtitle a{
  text-align: center !important;
  margin-top: 10px !important;
  text-shadow: 1px 3px 5px #AAA !important;
  font-family: "Lato", sans-serif !important;
}
.home-icon a{
  display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    line-height: normal;
    height: auto;
    min-height: 20px;
    min-width: 20px;
    overflow: hidden;
    margin: 0 5px 0 -1px;
    padding: 0 24px;
    cursor: pointer;
    pointer-events: auto;
}
.c-body{
  font-family: "Lato", sans-serif !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.carousel-caption{
  color:#000  !important;
}
.row{
  margin:0px;
}
</style>

<template>
  <CFooter :fixed="false" :style="'background-color: '+c2+' !important; color: #FFF;'">
    <div>
      <a href="/" target="_blank" style="color: #FFF !important;">{{name}}</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} {{slogan}}</span>
    </div>
    <div class="mfs-auto text-center">
        
    </div>
    <div class="mfs-auto">
        <a href="javascript:;" style="color: #FFF !important;" @click="anchor('terms')">PRIVACIDAD</a>
    </div>
  </CFooter>
</template>

<script>
import store from '../store'
import ws from '../services/general';

export default {
  name: 'TheFooter',
  props: {
    c1: {
      type: String,
      default: '#000000'
    },
    c2: {
      type: String,
      default: '#F29200'
    }
  },
  data() {
      return {
        name: 'Gran Rifa',
        url: '/',
        slogan: '',
        menu: []
      }
  },
  mounted: async function() {
    
  },
  methods: {
    anchor(anchor_id){
      this.$emit("anchor", anchor_id );
    }
  } 
}
</script>
<style>
a:hover{
  text-transform: none !important;
  text-decoration: none !important;
  color: white !important;
}

</style>